import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react"
import { ParanetServer } from "../../../entities"
import { ParanetService } from "../../../services"

interface Props {
  paranet: ParanetServer
  changeParanet: (paranet: ParanetServer) => void
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export default function ParanetConnectModal(props: Props) {
  const toast = useToast()

  const [form, setForm] = useState({
    loginId: "",
    password: "",
  })

  useEffect(() => {
    setForm({
      loginId: props.paranet.loginId,
      password: "",
    })
  }, [])

  const connect = async () => {
    if (!form.password) {
      toast({
        title: "Type your password",
        position: "top",
        duration: 2000,
        status: "error",
      })
      return
    }

    try {
      const paranetUpdated = await ParanetService.login(
        props.paranet,
        form.loginId,
        form.password
      )

      console.log("modal")
      props.changeParanet(paranetUpdated)

      props.onClose()
    } catch (error: any) {
      toast({
        title: error?.message,
        position: "top",
        duration: 2000,
        status: "error",
      })
    }
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paranet Server Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Login ID:</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.loginId}
                onChange={(event) =>
                  setForm({ ...form, loginId: event.target.value })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                size="lg"
                type="password"
                value={form.password}
                onChange={(event) =>
                  setForm({ ...form, password: event.target.value })
                }
              />
            </FormControl>
            <Flex alignContent="center" marginTop="20px">
              <Button margin="auto" onClick={connect}>
                Connect
              </Button>
              <Button margin="auto" onClick={props.onClose}>
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
