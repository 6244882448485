import React, { useEffect, useState } from "react"
import Layout from "../../../components/ui/layout/Layout"
import { ParanetService } from "../../../services"
import { Actor } from "../../../entities"
import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { useParanetServersStore } from "../../../store/useParanetServersStore"

export default function ActorListPage() {
  const paranet = useParanetServersStore((state) => state.selectedServer)

  const [actors, setActors] = useState<Actor[]>([])

  useEffect(() => {
    load()
    console.log("selected", paranet)
  }, [paranet])

  const load = () => {
    if (!paranet) return
    ParanetService.getBaseActors(paranet).then((res: Actor[]) => setActors(res))
  }

  return (
    <Layout>
      <>
        <TableContainer>
          <Table variant="striped">
            <TableCaption>Base Actors</TableCaption>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Kind</Th>
              </Tr>
            </Thead>
            <Tbody>
              {actors.map((actor) => (
                <Tr key={actor.id}>
                  <Td>{actor.id}</Td>
                  <Td>{actor.name}</Td>
                  <Td>{actor.kind}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    </Layout>
  )
}
