import axios from "axios"

export class RestClient {
  public static async DoRequest(
    url: string,
    method = "GET",
    data = {},
    aditionalHeaders: any = {}
  ) {
    const headers: any = {}
    headers["Content-Type"] = "application/json"
    headers.Accept = "application/json"

    for (const idx in aditionalHeaders) {
      headers[idx] = aditionalHeaders[idx]
    }

    const requestConfig: any = {
      method,
      headers,
      crossdomain: true,
      url,
      data,
    }

    const response = await axios(requestConfig)

    return response.data
  }
}
