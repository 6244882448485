// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashboardContent {
  display: flex;
}
#dashboardContent .content {
  width: 100%;
  min-height: 900px;
  padding: 1.5rem;
}
#dashboardContent .details {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  border-top: 3px solid #0d99ff;
  border-left: 3px solid #0d99ff;
  border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/dashboard-content/dashboardContent.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,WAAA;EACA,iBAAA;EACA,eAAA;AACJ;AAEE;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,6BAAA;EACA,8BAAA;EACA,qBAAA;AAAJ","sourcesContent":["#dashboardContent {\n  display: flex;\n\n  .content {\n    width: 100%;\n    min-height: 900px;\n    padding: 1.5rem;\n  }\n\n  .details {    \n    width: 100%;\n    max-width: 300px;\n    padding: 0.5rem;\n    border-top: 3px solid #0d99ff;\n    border-left: 3px solid #0d99ff;\n    border-radius: 0.5rem;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
