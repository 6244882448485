import React from "react"

import "./dashboardContent.scss"

interface Props {
  children: JSX.Element | JSX.Element[]
}

export default function DashboardContent(props: Props) {
  return (
    <div id="dashboardContent">
      <div className="content">{props.children}</div>
      <div className="details">Details</div>
    </div>
  )
}
