import { ParanetServer } from "../entities"

const storageKey = "@paracord-servers"

type ParanetServerStorageType = Record<string, ParanetServer>

export default class ParanetServersStorage {
  static addUpdate(paranet: ParanetServer) {
    const servers = this.get()
    if (!servers) return false

    servers[paranet.name] = paranet
    this.save(servers)
    return true
  }

  static remove(serverName: string): boolean {
    const servers = this.get()
    if (!servers) return false

    delete servers[serverName]
    this.save(servers)
    return true
  }

  static save(items: ParanetServerStorageType) {
    const servers: ParanetServer[] = []
    const keys = Object.keys(items)
    for (const key in keys) {
      servers.push(items[keys[key]])
    }

    localStorage.setItem(storageKey, JSON.stringify(servers))
  }

  static get(): ParanetServerStorageType | null {
    const storedItem = localStorage.getItem(storageKey)
    if (!storedItem) return null

    const items: ParanetServer[] = JSON.parse(storedItem)
    return items.reduce((acc, server) => {
      return {
        ...acc,
        [server.name]: new ParanetServer(server),
      }
    }, {})
  }

  static clear() {
    localStorage.removeItem(storageKey)
  }
}
