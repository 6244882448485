// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidebar {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  padding: 5rem 0.5rem 0 0.5rem;
  border-right: 3px solid #0d99ff;
  border-radius: 0.5rem;
}
#sidebar .buttonGroup {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#sidebar .buttonGroup a {
  width: 100%;
  border: 2px solid #0d99ff;
  background: #FFFFFF;
  border-radius: 1rem;
  color: #000;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: ease-in-out 0.2s;
}
#sidebar .buttonGroup a:hover, #sidebar .buttonGroup a.selected {
  background-color: #0d99ff;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/sidebar/sidebar.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,+BAAA;EACA,qBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;AACI;EACE,WAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,4BAAA;AACN;AACM;EACE,yBAAA;EACA,WAAA;AACR","sourcesContent":["#sidebar {\n  min-width: 250px;\n  display: flex;\n  flex-direction: column;  \n  padding: 5rem 0.5rem 0 0.5rem;\n  border-right: 3px solid #0d99ff;\n  border-radius: 0.5rem;  \n\n  .buttonGroup {\n    width: 100%;\n    display: flex;\n    gap: 0.5rem;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n    a {\n      width: 100%;\n      border: 2px solid #0d99ff;\n      background: #FFFFFF;\n      border-radius: 1rem;\n      color: #000;\n      padding: 0.5rem 0.5rem;      \n      display: flex;\n      justify-content: center;\n      align-items: center;\n      text-decoration: none;\n      transition: ease-in-out 0.2s;\n\n      &:hover, &.selected {\n        background-color: #0d99ff;\n        color: #fff;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
