import React, { useEffect, useState } from "react"
import { useDisclosure, useToast } from "@chakra-ui/react"
import ParanetConfigModal from "../../paranet/config-modal/ParanetConfigModal"
import { useParanetServersStore } from "../../../store/useParanetServersStore"
import { ParanetServer } from "../../../entities"
import { FaPlus } from "react-icons/fa"

import "./sidebar.scss"
import { useNavigate } from "react-router-dom"
import { ParanetService } from "../../../services"
import ParanetConnectModal from "../../paranet/connect-modal/ParanetConnectModal"

export default function Sidebar() {
  const navite = useNavigate()
  const toast = useToast()

  const [selectedParanet, setSelectedParanet] = useState<ParanetServer>()
  const [paranetToConnect, setParanetToConnect] = useState<ParanetServer>()
  const [initializeServers, servers, selectedServer] = useParanetServersStore(
    (state) => [state.initialize, state.servers, state.selectedServer]
  )

  const paranetConfigDisclosure = useDisclosure()
  const paranetConnectDisclosure = useDisclosure()

  const updateParanet = useParanetServersStore((store) => store.update)
  const paranets = useParanetServersStore((store) => store.list)
  const selectServer = useParanetServersStore((state) => state.selectServer)

  useEffect(() => {
    initializeServers()

    if (selectedServer) {
      setSelectedParanet(selectedServer)
      return
    }

    const isLocal = window.location.href.startsWith("http://localhost")
    const defaultParanet = servers[isLocal ? "local" : "grokit"]
    setSelectedParanet(defaultParanet)
  }, [])

  const selectParanet = async (name: string) => {
    if (!selectedParanet) return
    if (name === selectedParanet.name) return

    let paranet = servers[name]
    console.log("paranet", paranet)

    if (paranet.needsRefresh()) {
      paranet = await ParanetService.refreshToken(paranet)
    }

    const isValidToken = await ParanetService.validateAccessToken(paranet)
    if (isValidToken === true) {
      changeParanet(paranet)
    } else {
      setParanetToConnect(paranet)
      paranetConnectDisclosure.onOpen()
    }
  }

  const changeParanet = (paranet: ParanetServer) => {
    paranet.connected = "yes"
    updateParanet(paranet)

    setSelectedParanet(paranet)
    selectServer(paranet.name)

    toast({
      title: `Connected to ${paranet.name}`,
      position: "top",
      duration: 2000,
      status: "success",
    })

    navite("/")
  }

  return (
    <section id="sidebar">
      <div className="buttonGroup">
        <a href="#" onClick={paranetConfigDisclosure.onOpen}>
          <FaPlus />
        </a>
        {paranets?.().map((paranet) => (
          <a
            href="#"
            key={paranet.name}
            className={paranet.name === selectedParanet?.name ? "selected" : ""}
            onClick={() => selectParanet(paranet.name)}
          >
            {paranet.name}
          </a>
        ))}
      </div>
      <ParanetConfigModal
        isOpen={paranetConfigDisclosure.isOpen}
        onOpen={paranetConfigDisclosure.onOpen}
        onClose={paranetConfigDisclosure.onClose}
      />
      {paranetToConnect && (
        <ParanetConnectModal
          paranet={paranetToConnect}
          changeParanet={changeParanet}
          isOpen={paranetConnectDisclosure.isOpen}
          onOpen={paranetConnectDisclosure.onOpen}
          onClose={paranetConnectDisclosure.onClose}
        />
      )}
    </section>
  )
}
