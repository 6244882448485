import React from "react"
import { CgProfile } from "react-icons/cg"
import { Link, useLocation } from "react-router-dom"

import "./header.scss"

export default function Header() {
  const location = useLocation()

  return (
    <section id="header">
      <div className="buttonGroup">
        <Link to="/" className={location.pathname === "/" ? "selected" : ""}>
          Overview
        </Link>
        <Link
          to="/deployed"
          className={location.pathname === "/deployed" ? "selected" : ""}
        >
          Deployed
        </Link>
        <Link
          to="/actor"
          className={location.pathname === "/actor" ? "selected" : ""}
        >
          Actor Units
        </Link>
        <Link
          to="/ora"
          className={location.pathname === "/ora" ? "selected" : ""}
        >
          ORA
        </Link>
        <Link
          to="/external"
          className={location.pathname === "/external" ? "selected" : ""}
        >
          External
        </Link>
        <Link
          to="/ledger"
          className={location.pathname === "/ledger" ? "selected" : ""}
        >
          Ledger
        </Link>
      </div>
      <div className="profile">
        <div className="image">
          <CgProfile />
        </div>
      </div>
    </section>
  )
}
