// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
}
#header .buttonGroup {
  width: 90%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
#header .buttonGroup a {
  border: 2px solid #0d99ff;
  background: #FFFFFF;
  border-radius: 1rem;
  color: #000;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: ease-in-out 0.2s;
}
#header .buttonGroup a:hover, #header .buttonGroup a.selected {
  background-color: #0d99ff;
  color: #fff;
}
#header .profile {
  display: flex;
  padding-right: 1rem;
}
#header .profile .image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d99ff;
  color: #fff;
  border-radius: 50%;
  height: 2rem;
}
#header .profile .image svg {
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/header/header.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACE,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,oBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,4BAAA;AACN;AACM;EACE,yBAAA;EACA,WAAA;AACR;AAIE;EACE,aAAA;EACA,mBAAA;AAFJ;AAII;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAFN;AAIM;EACE,eAAA;AAFR","sourcesContent":["#header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 0.75rem 0;\n\n  .buttonGroup {\n    width: 90%;\n    display: flex;\n    gap: 0.5rem;\n    align-items: center;\n    justify-content: center;\n\n    a {\n      border: 2px solid #0d99ff;\n      background: #FFFFFF;\n      border-radius: 1rem;\n      color: #000;\n      padding: 0.5rem 2rem;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      text-decoration: none;\n      transition: ease-in-out 0.2s;\n\n      &:hover, &.selected {\n        background-color: #0d99ff;\n        color: #fff;\n      }\n    }\n  }\n\n  .profile {\n    display: flex;\n    padding-right: 1rem;\n\n    .image {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background-color: #0d99ff;\n      color: #fff;\n      border-radius: 50%;\n      height: 2rem;\n\n      svg {\n        font-size: 2rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
