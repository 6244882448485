import React from "react"
import Layout from "../../components/ui/layout/Layout"

export default function HomePage() {
  return (
    <Layout>
      <>Content</>
    </Layout>
  )
}
