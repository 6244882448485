// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashboardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/dashboard-container/dashboardContainer.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,aAAA;EACA,sBAAA;AAAF","sourcesContent":["#dashboardContainer {\n  width: 100%;\n  \n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
