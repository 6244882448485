export class Actor {
  id: string
  name: string
  kind: string
  model: JSON

  constructor(data: any) {
    this.id = data.id
    this.name = data.name
    this.kind = data.kind
    this.model = data.model
  }
}
