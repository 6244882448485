import React from "react"
import { Route, Routes } from "react-router-dom"
import HomePage from "./home/HomePage"
import ActorListPage from "./actor/list/ActorListPage"

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/deployed" element={<HomePage />} />
      <Route path="/actor" element={<ActorListPage />} />
      <Route path="/ora" element={<HomePage />} />
      <Route path="/external" element={<HomePage />} />
      <Route path="/ledger" element={<HomePage />} />
    </Routes>
  )
}

export default Router
