import React, { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useToast,
} from "@chakra-ui/react"
import { ParanetServer } from "../../../entities"
import { useParanetServersStore } from "../../../store/useParanetServersStore"

interface Props {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export default function ParanetConfigModal(props: Props) {
  const toast = useToast()

  const [form, setForm] = useState({
    name: "",
    loginId: "",
    url: "",
    wsUrl: "",
    debug: false,
  })

  const addParanet = useParanetServersStore((store) => store.add)

  const save = async () => {
    if (!form.name || !form.url || !form.wsUrl) {
      console.warn("missing required fields")
      return
    }

    const newParanet = new ParanetServer({
      name: form.name.toLowerCase().replace(" ", "-"),
      url: form.url,
      wsUrl: form.wsUrl,
      bedug: form.debug,
    })
    newParanet.loginId = form.loginId

    addParanet(newParanet)
    props.onClose()

    toast({
      title: "Paranet added!",
      position: "top",
      duration: 2000,
      status: "success",
    })
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paranet Server Configuration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.name}
                onChange={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>LoginID</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.loginId}
                onChange={(event) =>
                  setForm({ ...form, loginId: event.target.value })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>URL</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.url}
                onChange={(event) =>
                  setForm({ ...form, url: event.target.value })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>WS URL</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.wsUrl}
                onChange={(event) =>
                  setForm({ ...form, wsUrl: event.target.value })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Debug mode?</FormLabel>
              <Checkbox
                checked={form.debug}
                onChange={() => setForm({ ...form, debug: !form.debug })}
              >
                Yes
              </Checkbox>
            </FormControl>
            <Flex alignContent="center" marginTop="20px">
              <Button margin="auto" onClick={save}>
                Save
              </Button>
              <Button margin="auto" onClick={props.onClose}>
                Close
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
