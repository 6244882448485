import React from "react"

import "./dashboardContainer.scss"

interface Props {
  children: JSX.Element | JSX.Element[]
}

export default function DashboardContainer(props: Props) {
  return <div id="dashboardContainer">{props.children}</div>
}
