import React from "react"
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar"
import DashboardContainer from "../dashboard-container/DashboardContainer"
import DashboardContent from "../dashboard-content/DashboardContent"

import "./layout.scss"

interface Props {
  children: JSX.Element | JSX.Element[]
}

export default function Layout(props: Props) {
  return (
    <>
      <div id="main">
        <Sidebar />
        <DashboardContainer>
          <Header />
          <DashboardContent>{props.children}</DashboardContent>
        </DashboardContainer>
      </div>
    </>
  )
}
