import { ConnectionState } from "./ConnectedState"

export class ParanetServer {
  name: string
  url: string
  wsUrl: string
  debug: boolean
  connected: ConnectionState
  loginId: string
  token?: string
  refresh?: string
  refreshTimestamp: number // time of last refresh
  // skillsetDB: SkillsetDB;
  // client?: ParanetClient;
  // webSocket?: ReconnectingWebSocket

  wsHandler?: (evt: MessageEvent) => void

  logoutHandler?: () => void

  constructor(data: any = {}) {
    this.name = data.name || ""
    this.url = data.url || ""
    this.wsUrl = data.wsUrl || ""
    this.connected = "no"
    this.debug = data.debug === true
    this.token = data.token || ""
    this.refresh = data.refresh || ""
    this.refreshTimestamp = 0
    // this.skillsetDB = new SkillsetDB(url);
    if (this.name === "local") {
      this.loginId = "guest@1.0.0"
      this.token = "xyz"
    } else {
      this.loginId = data.loginId || "anonymous"
    }
  }

  public needsRefresh(): boolean {
    if (Date.now() - this.refreshTimestamp > 60000) return true
    return false
  }
}

//   async checkStoredLogin() {
//     const stored = await appStorage.getLogin(this.name);
//     if (stored) {
//       try {
//         const { username, token, refresh } = stored;
//         const options = {
//           serviceEndpoint: this.url,
//           actorId: username,
//         };
//         this.client = new ParanetClient(options);
//         this.client.setTokens(token, refresh);
//         this.loginId = `${username}@1.0.0`;
//         this.token = token;
//         this.refresh = refresh;
//         console.log(`Loaded stored ${this.name} credentials`);
//         return true;
//       } catch (err) {
//         /* empty */
//       }
//     }

//     return false;
//   }

//   // Logout handler is called if tokens expire and cannot be refreshed
//   setLogoutHandler(handler: () => void) {
//     this.logoutHandler = handler;
//   }

//   connect(callback: (name: string, status: boolean) => void) {
//     if (this.webSocket) {
//       if (this.connected === "pending" || this.connected === "yes") return;
//       else this.disconnect();
//     }

//     console.log("CONNECT", this.name);
//     this.connected = "pending";
//     //const obsId = (this.name === 'local') ? 'simulator@1.0.0' : 'paracord@1.0.0';
//     const webSocket = new ReconnectingWebSocket(
//       `${this.wsUrl}/agent/${memberName(this.loginId!)}`
//     );

//     webSocket.onopen = () => {
//       const initMsg = {
//         type: "Init",
//         body: this.name === "local" ? null : this.token,
//       };
//       const obsMsg = {
//         type: "InitObserver",
//         body: {
//           id: this.loginId,
//           observance: {},
//         },
//       };
//       console.log(initMsg);
//       webSocket.send(JSON.stringify(initMsg));
//       console.log(obsMsg);
//       webSocket.send(JSON.stringify(obsMsg));
//       if (this.wsHandler) webSocket.onmessage = this.wsHandler;
//       this.connected = "yes";
//       callback(this.name, true);
//     };
//     webSocket.onclose = () => {
//       this.connected = "pending";
//       callback(this.name, false);
//     };
//     this.webSocket = webSocket;
//   }

//   setHandler(handler: (evt: MessageEvent) => void) {
//     this.wsHandler = handler;
//     if (this.webSocket) this.webSocket.onmessage = handler;
//   }

//   disconnect() {
//     console.log("DISCONNECT", this.name);
//     this.webSocket?.close();
//     this.webSocket = undefined;
//     this.connected = "no";
//   }

//   // Make a graphql request.
//   // Handles authentication and token refresh

//   async graphqlRequest(
//     name: string,
//     query: string,
//     variables: Record<string, string | number>
//   ) {
//     const headers = this.makeHeaders();
//     const requestOptions = {
//       method: "POST",
//       headers,
//       body: JSON.stringify({
//         query,
//         variables,
//       }),
//     };
//     const result = await this.post(`${this.url}/graphql`, requestOptions);
//     if ("errorCode" in result) {
//       // HTTP error status
//       throw new Error(`HTTP error ${result.errorCode}: ${result.content}`);
//     } else if (result.errors) {
//       // GraphQL errors
//       throw new Error(`GraphQL ${name} error: ${result.errors[0].message}`);
//     }
//     return result.data[name];
//   }

//   async skillRequest(request: SkillMessageRequest) {
//     const headers = this.makeHeaders();
//     const requestOptions = {
//       method: "POST",
//       headers,
//       body: JSON.stringify(request),
//     };
//     return this.post(`${this.url}/skill`, requestOptions);
//   }

//   async sendQuestion(conv: Conversation, qu: SkillQnA, data: MessageData) {
//     const message = {
//       conversation: conv.initiator.memberId,
//       message: {
//         message_type: "Question",
//         id: qu.id,
//         data,
//       },
//     };
//     await this.sendPncpMessage(message);
//   }

//   async sendStatus(conv: Conversation, data: MessageData) {
//     const memberId =
//       conv.initiator.actorId === this.loginId
//         ? conv.initiator.memberId
//         : conv.recipient?.memberId;
//     if (memberId) {
//       const message = {
//         conversation: memberId,
//         message: {
//           message_type: "Status",
//           data,
//         },
//       };
//       await this.sendPncpMessage(message);
//     } else {
//       throw Error("Conversation is missing member id");
//     }
//   }

//   async sendReply(conv: Conversation, m: Message, data: MessageData) {
//     if (m.contents.type === "PncpMessage") {
//       const message = {
//         conversation: conv.initiator.memberId,
//         message: {
//           message_type: "Answer",
//           reply_to: m.id,
//           data,
//         },
//       };
//       await this.sendPncpMessage(message);
//     } else if (m.contents.type === "SkillRequest") {
//       const headers = this.makeHeaders();
//       const message = {
//         conversation: conv.recipient?.memberId,
//         message: {
//           message_type: "Response",
//           data,
//         },
//       };
//       console.dir(message);
//       const packet = { type: "Message", ...message };
//       const responseRequest = {
//         method: "POST",
//         headers,
//         body: JSON.stringify(packet),
//       };
//       return await this.post(
//         `${this.url}/message/${this.loginId}`,
//         responseRequest
//       );
//     }
//   }

//   private async sendPncpMessage(message: PncpMessage) {
//     const headers = this.makeHeaders();
//     const packet = { type: "Message", ...message };
//     const pncpRequest = {
//       method: "POST",
//       headers,
//       body: JSON.stringify(packet),
//     };
//     return await this.post(`${this.url}/data`, pncpRequest);
//   }

//   private makeHeaders() {
//     const headers: Record<string, string> = {
//       "Content-Type": "application/json",
//       "X-ACTOR-ID": this.loginId!,
//     };
//     if (this.name !== "local") headers.Authorization = `Bearer ${this.token}`;
//     return headers;
//   }

//   // HTTP post with automatic token refresh
//   private async post(url: string, options: RequestInit) {
//     let resp = await fetch(url, options).catch(console.error);
//     if (!resp) {
//       return { errorCode: 0, content: "Server unreachable" };
//     }

//     // If unauthorized and didn't already refresh, try refreshing token
//     // REST APIs return 401 status
//     if (resp.status == 401) {
//       if (await this.refreshToken()) {
//         options.headers = this.makeHeaders();
//         resp = await fetch(url, options);
//       }
//     }

//     if (resp.status == 200) {
//       let result = await resp.json();
//       // GraphQL APIs return Forbidden in response body
//       if (this.isUnauthorizedGraphQL(result)) {
//         if (await this.refreshToken()) {
//           options.headers = this.makeHeaders();
//           const resp2 = await fetch(url, options);
//           if (resp2.status == 200) result = await resp2.json();
//         }
//       }

//       return result;
//     }

//     const body = await resp.text();
//     return { errorCode: resp.status, content: body };
//   }

//   private isUnauthorizedGraphQL(result: { errors?: { message: string }[] }) {
//     return (
//       "errors" in result &&
//       Array.isArray(result.errors) &&
//       result.errors.some(
//         (err) =>
//           "message" in err && err.message.toLowerCase().includes("forbidden")
//       )
//     );
//   }

//   private async refreshToken() {
//     if (this.client && Date.now() - this.refreshTimestamp > 60000) {
//       try {
//         console.log(`Refreshing ${this.name} credentials ...`);
//         const { access_token, refresh_token } = await this.client.refreshAuth();
//         if (access_token && refresh_token) {
//           this.token = access_token;
//           this.refresh = refresh_token;
//           // async, but don't need to wait
//           this.storeLogin();
//           console.log(`Refreshed ${this.name} credentials`);
//           return true;
//         }
//       } catch (err) {
//         if (err instanceof Error) {
//           if (err.message.toLowerCase().includes("expired")) {
//             console.log(
//               "Refresh credentials expired, removing store credentials.  New login required."
//             );
//             if (this.webSocket) this.disconnect();
//             await this.clearLogin();
//             if (this.logoutHandler) this.logoutHandler();
//           } else {
//             console.log(
//               `Error refreshing ${this.name} credentials: ${err.message}`
//             );
//           }
//         }
//       }
//     }

//     return false;
//   }

//   // paranet-client version of this is broken
//   async refreshAuth() {
//     if (this.client) {
//       const result = await this.client.post<TokenResponse | { error: string }>(
//         this.url,
//         "token/refresh",
//         {
//           refresh_token: this.refreshToken,
//         }
//       );
//       if ("access_token" in result) {
//         this.client.setTokens(result.access_token, result.refresh_token);
//       }
//       return result;
//     }
//     return { error: "missing client" };
//   }

//   private async clearLogin() {
//     this.loginId = "anonymous";
//     this.token = undefined;
//     this.refresh = undefined;
//     this.client = undefined;
//     await appStorage.deleteLogin(this.name);
//   }

//   private async storeLogin() {
//     if (this.loginId && this.token && this.refresh) {
//       await appStorage.writeLogin({
//         name: this.name,
//         username: memberName(this.loginId),
//         token: this.token,
//         refresh: this.refresh,
//       });
//     }
//   }
// }

// export type ConnectionState = "yes" | "no" | "pending";

// export type LoginResult =
//   | {
//       token: string;
//       refresh: string;
//     }
//   | {
//       error: string;
//     };

// export interface SkillMessageRequest {
//   author: RequestCaller;
//   body: RequestBody;
//   targetActorId?: string;
// }

// export interface ActorCaller {
//   type: "actor";
//   actorId: string;
// }

// export interface ParaviewCaller {
//   type: "paraview";
//   actorId: string;
// }

// export type RequestCaller = ActorCaller | ParaviewCaller;

// export interface SkillRequestBody {
//   type: "skill";
//   subject: string;
//   action: string;
//   data: MessageData;
// }

// export type RequestBody = SkillRequestBody;

// export interface SkillRequestResponse {
//   id: string;
//   messageId: string;
// }

// const actorCache: Record<string, ActorDB> = {};

// export const loadActorsFromParanet = async (
//   paranetName: string
// ): Promise<Actor[]> => {
//   if (!actorCache[paranetName]) {
//     //const store: Store<> = useParanetServersStore;
//     //console.log(useParanetServersStore.getState().servers);
//     const paranet = paranets[paranetName];
//     if (!paranet) {
//       throw new Error(`Invalid paranet ${paranetName}`);
//     }
//     actorCache[paranetName] = await listActors(paranet);
//   }
//   return Object.values(actorCache[paranetName]).map(
//     (set) => set.versions[set.current]
//   );
// };

// export function getActorId(net: string, name: string) {
//   console.log(Object.keys(actorCache));
//   if (actorCache[net] && actorCache[net][name]) {
//     return `${name}@${actorCache[net][name].current}`;
//   }
//   throw new Error(`Invalid actor ${name}`);
// }

// export const paranets: Record<string, ParanetServer> = {
//   local: new ParanetServer(
//     "local",
//     "http://localhost:3132",
//     "ws://localhost:3131",
//     true
//   ),
//   grokit: new ParanetServer(
//     "grokit",
//     "https://grokit.paranet.ai/api/paranet-service",
//     "wss://grokit.paranet.ai/api/paranet-service",
//     false
//   ),
// };
