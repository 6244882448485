import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client"

export class GraphqlClient {
  public static getClient(
    uri: string,
    headers: Record<string, string> | undefined = undefined
  ): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
      uri,
      headers,
    })

    const cache = new InMemoryCache({})

    return new ApolloClient({
      link: httpLink,
      cache,
    })
  }
}
