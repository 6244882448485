import { Actor, ParanetServer } from "../entities"
import { ParanetRepository } from "../repositories"
import * as jose from "jose"

export class ParanetService {
  public static async login(
    paranet: ParanetServer,
    loginId: string,
    password: string
  ): Promise<ParanetServer> {
    try {
      const url = paranet.url + "/login"
      console.log("url", url)
      const loginData = await ParanetRepository.login(url, loginId, password)

      paranet.loginId = loginId
      paranet.token = loginData.token
      paranet.refresh = loginData.refresh
    } catch (error: any) {
      console.log("error", error)
      if (error?.response?.data?.error) {
        throw Error(error.response.data.error)
      }
    }

    return paranet
  }

  public static async refreshToken(
    paranet: ParanetServer
  ): Promise<ParanetServer> {
    const url = paranet.url + "/token/refresh"
    if (paranet.refresh) {
      await ParanetRepository.refreshToken(url, paranet.refresh)
    }

    return paranet
  }

  public static validateAccessToken(paranet: ParanetServer): boolean {
    if (!paranet.token) return false

    try {
      jose.decodeJwt(paranet.token)
    } catch (error) {
      return false
    }

    return true
  }

  public static async getBaseActors(paranet: ParanetServer): Promise<Actor[]> {
    return await ParanetRepository.getBaseActors(paranet)
  }
}
