import { create } from "zustand"
import { ParanetServer } from "../entities"
import ParanetServersStorage from "../util/paranetServersStorage"

interface ParanetServersStoreActions {
  initialize: () => void
  add: (server: ParanetServer) => void
  update: (server: ParanetServer) => void
  remove: (serverName: string) => void
  list: () => ParanetServer[]
  get: (serverName: string) => ParanetServer | null
  selectServer: (serverName: string) => void
}

interface ParanetServersStoreState {
  servers: Record<string, ParanetServer>
  selectedServer: ParanetServer | null
}

const initialState: ParanetServersStoreState = {
  servers: {
    local: new ParanetServer({
      name: "local",
      url: "http://localhost:3132",
      wsUrl: "ws://localhost:3131",
      debug: true,
    }),
    grokit: new ParanetServer({
      name: "grokit",
      url: "https://grokit.paranet.ai/api/paranet-service",
      wsUrl: "wss://grokit.paranet.ai/api/paranet-service",
      debug: false,
    }),
  },
  selectedServer: null,
}

export const useParanetServersStore = create<
  ParanetServersStoreState & ParanetServersStoreActions
>()((set, get) => ({
  ...initialState,
  initialize: () => {
    const localStorageServers = ParanetServersStorage.get()
    if (!localStorageServers) {
      ParanetServersStorage.save(initialState.servers)
      return
    }

    const localServers: Record<string, ParanetServer> = {}
    const keys = Object.keys(localStorageServers)
    for (const key in keys) {
      const localStorageServer = localStorageServers[keys[key]]
      localServers[keys[key]] = localStorageServer
    }

    const mergedServers = {
      ...initialState.servers,
      ...localServers,
    }
    ParanetServersStorage.save(mergedServers)
    set({ servers: mergedServers })
  },
  add: (server: ParanetServer) => {
    set(({ servers }) => {
      servers[server.name] = server
      ParanetServersStorage.addUpdate(server)
      return servers
    })
  },
  update: (server: ParanetServer) => {
    set(({ servers }) => {
      servers[server.name] = server
      ParanetServersStorage.addUpdate(server)
      return { servers }
    })
  },
  remove: (serverName: string) => {
    set(({ servers }) => {
      delete servers[serverName]
      ParanetServersStorage.remove(serverName)
      return servers
    })
  },
  list: () => {
    const servers = get().servers
    const keys = Object.keys(servers)
    const list: ParanetServer[] = []
    for (const key in keys) {
      const server = servers[keys[key]]
      list.push(server)
    }

    return list
  },
  get: (serverName: string) => get().servers[serverName],
  selectServer: (serverName: string) =>
    set(({ servers, selectedServer }) => {
      selectedServer = get().servers[serverName]
      return { servers, selectedServer }
    }),
}))
