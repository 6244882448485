// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
  width: 100%;
  display: flex;
  margin: 0;
  border: 3px solid #0d99ff;
  border-radius: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/layout/layout.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,SAAA;EACA,yBAAA;EACA,qBAAA;AACF","sourcesContent":["#main {\n  width: 100%;\n  display: flex;\n  margin: 0;\n  border: 3px solid #0d99ff;\n  border-radius: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
