import { gql } from "@apollo/client"
import { Actor, ParanetServer } from "../entities"
import { GraphqlClient } from "./clients/GraphqlClient"
import { RestClient } from "./clients/RestClient"

export class ParanetRepository {
  public static async login(
    url: string,
    id: string,
    password: string
  ): Promise<{ token: string; refresh: string }> {
    const data = { id, password }

    const res = await RestClient.DoRequest(url, "POST", data)

    const token: string = res.access_token || ""
    const refresh: string = res.refresh_token || ""

    return { token, refresh }
  }

  public static async refreshToken(
    url: string,
    refreshToken: string
  ): Promise<{ token: string; refresh: string }> {
    const data = { refresh_token: refreshToken }

    const res = await RestClient.DoRequest(url, "POST", data)

    const token: string = res.access_token || ""
    const refresh: string = res.refresh_token || ""

    return { token, refresh }
  }

  public static async getBaseActors(paranet: ParanetServer): Promise<Actor[]> {
    const client = GraphqlClient.getClient(`${paranet.url}/graphql`)

    const query = gql`
      query listBaseActors {
        listBaseActors {
          id
          name
          kind
          model
        }
      }
    `

    const result = await client.query({ query })

    const actors: Actor[] = []
    for (const actor of result.data.listBaseActors) {
      actors.push(new Actor(actor))
    }

    return actors
  }
}
